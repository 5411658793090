import MixinsFuntions from "../../../../service/MixinsFuntions";

export default {
  name: "ListTMortgageAccount",
  mixins: [MixinsFuntions],
  data() {
    return {
      property: {
        animation: {
          checkingCif: {
            isLoading: false,
          },
        },
        modal: {
          showModalCheckingCif: false,
          showModalResultCif: false,
        },
        listElement: {
          mortgageAccount: {
            rows: 0,
            perPage: 10,
            currentPage: 1,
            downloading: false,
            message: "",
          },
          cif: {
            rows: 0,
            perPage: 5,
            currentPage: 1,
            downloading: false,
            message: "",
          },
        },
        filterDto: {
          mortgageNumber: "",
          cifIdName: "",
          dateFrom: "",
          dateTo: "",
          statusId: "",
        },
      },
      options: {
        office: [],
        statusAccount: [],
      },
      dataForm: {
        cifChecking: {
          cifIdName: "",
          cifNumber: "",
          officeCode: "",
        },
      },
      session: {
        cifChecking: {
          cifIdName: "",
          cifNumber: "",
          officeCode: "",
        },
      },
      table: {
        totalNominalMortgage: 0,
        totalRekening: 0,
        data: {
          mortgageAccount: [],
          cif: [],
        },
      },
    };
  },
  methods: {
    async findMortgageAccount() {
      this.table.data.mortgageAccount = [];
      this.property.listElement.mortgageAccount.downloading = true;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "loan",
          reqUrl: "mortgage-account/simple-list",
          payload: {
            mortgageNumber: this.property.filterDto.mortgageNumber,
            cifIdName: this.property.filterDto.cifIdName,
            dateFrom: this.property.filterDto.dateFrom,
            dateTo: this.property.filterDto.dateTo,
            statusId: this.property.filterDto.statusId,
            page: 0,
            size: this.property.listElement.mortgageAccount.perPage,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.mortgageAccount = resp.data.data.page.content;
            this.property.listElement.mortgageAccount.rows =
              resp.data.data.page.totalElements;
            this.property.listElement.mortgageAccount.currentPage =
              resp.data.data.page.pageable.pageNumber + 1;
            this.table.totalRekening = resp.data.data.totalMortgageAccount;
            this.table.totalNominalMortgage =
              resp.data.data.totalNominalMortgage;
          } else {
            this.failedGetMortgageAccount(resp);
            this.table.totalRekening = 0;
            this.table.totalNominalMortgage = 0;
          }
        }, 500);
      } catch (error) {
        setTimeout(() => {
          this.handleErrorGetMortgageAccount(error);
        }, 500);
      } finally {
        setTimeout(() => {
          this.property.listElement.mortgageAccount.downloading = false;
        }, 1000);
      }
    },
    closePopUpCheckingCif() {
      this.property.modal.showModalCheckingCif = false;
      this.dataForm.cifChecking.cifNumber = "";
      this.dataForm.cifChecking.officeCode = "";
      this.dataForm.cifChecking.cifIdName = "";
    },
    closePopUpResultCheckingCif() {
      this.table.data.cif = [];
      this.property.listElement.cif.rows = 0;
      this.property.listElement.cif.currentPage = 1;
      this.property.listElement.cif.perPage = 5;
    },
    async checkingCif() {
      if (window.navigator.onLine === false) {
        this.$buefy.dialog.alert(this.$NotificationUtils.connectionsError);
        return;
      }
      this.property.animation.checkingCif.isLoading = true;
      this.session.cifChecking.cifNumber = this.dataForm.cifChecking.cifNumber;
      this.session.cifChecking.cifIdName = this.dataForm.cifChecking.cifIdName;
      this.session.cifChecking.officeCode = this.dataForm.cifChecking.officeCode;
      this.dataForm.cifChecking.cifNumber = "";
      this.dataForm.cifChecking.cifIdName = "";
      this.dataForm.cifChecking.officeCode = "";
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "transaction",
          reqUrl: "cif/simple-list",
          payload: {
            officeCode: this.session.cifChecking.officeCode,
            cifNumber: this.session.cifChecking.cifNumber,
            page: 0,
            size: this.property.listElement.cif.perPage,
            cifIdName: this.session.cifChecking.cifIdName,
            cifMotherName: "",
            categoryId: "",
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.cif = resp.data.data.content;
            this.property.listElement.cif.rows = resp.data.data.totalElements;
            this.property.modal.showModalCheckingCif = false;
            this.property.modal.showModalResultCif = true;
          } else {
            this.dataForm.cifChecking.cifNumber = this.session.cifChecking.cifNumber;
            this.dataForm.cifChecking.cifIdName = this.session.cifChecking.cifIdName;
            this.dataForm.cifChecking.officeCode = this.session.cifChecking.officeCode;
            this.session.cifChecking.officeCode = "";
            this.session.cifChecking.cifNumber = "";
            this.session.cifChecking.cifIdName = "";
            this.$buefy.dialog.alert({
              title: "Gagal !",
              message: resp.data.errorFields
                ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
                : resp.data.message,
              confirmText: "Ok",
              type: "is-danger",
            });
          }
        }, 500);
      } catch (error) {
        this.dataForm.cifChecking.cifNumber = this.session.cifChecking.cifNumber;
        this.dataForm.cifChecking.cifIdName = this.session.cifChecking.cifIdName;
        this.dataForm.cifChecking.officeCode = this.session.cifChecking.officeCode;
        this.session.cifChecking.officeCode = "";
        this.session.cifChecking.cifNumber = "";
        this.session.cifChecking.cifIdName = "";
        console.log(error.response);
        setTimeout(() => {
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: error.response
              ? error.response.data.errorFields
                ? this.globalNotificationErrorFieldToText(
                    error.response.data.errorFields
                  )
                : error.response.statusText
              : "Terjadi Kesalahan",
            confirmText: "Ok",
            type: "is-danger",
          });
        }, 500);
      } finally {
        setTimeout(() => {
          this.property.animation.checkingCif.isLoading = false;
        }, 500);
      }
    },
    routeToPageAddMortgageAccount(props) {
      const payload = {
        cifId: props.row.cifId,
      };
      if (props.row.statusId !== "3") {
        this.property.modal.showModalResultCif = false;
        this.$buefy.toast.open({
          message: `Cif Belum Aktif !`,
          type: "is-danger",
          duration: 3000,
          position: "is-top",
          queue: false,
        });
        return;
      }
      this.$router.push(
        `/loan/mortgage-account/new?refId=${this.encryptBASE64(
          JSON.stringify(payload)
        )}`
      );
    },
    async deleteMortgageAccount(props) {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      }
      this.$buefy.dialog.confirm({
        message: "Simpan Data Ini ?",
        type: "is-success",
        onConfirm: async () => {
          try {
            const resp = await this.$store.dispatch({
              type: "DELETE_DATA",
              endPoint: "loan",
              reqUrl: "mortgage-account/" + props.row.mortgageAccountId,
            });
            if (resp.data.code === "SUCCESS") {
              this.$buefy.toast.open(this.$NotificationUtils.success);
              setTimeout(() => {
                this.property.listElement.mortgageAccount.currentPage = 1;
                this.property.filterDto.mortgageNumber = "";
                this.property.filterDto.cifIdName = "";
                this.property.filterDto.dateFrom = "";
                this.property.filterDto.dateTo = "";
                this.property.filterDto.statusId = "";
                this.property.listElement.mortgageAccount.rows = 0;
                this.getMortgageAccount();
              }, 1000);
            } else {
              this.$buefy.dialog.alert({
                title: "Gagal !",
                message: resp.data.errorFields
                  ? this.globalNotificationErrorFieldToText(
                      resp.data.errorFields
                    )
                  : resp.data.message,
                confirmText: "Ok",
                type: "is-danger",
              });
            }
          } catch (error) {
            this.$buefy.dialog.alert({
              title: "Gagal !",
              message: error.response
                ? error.response.data.errorFields
                  ? this.globalNotificationErrorFieldToText(
                      error.response.data.errorFields
                    )
                  : error.response.statusText
                : "Terjadi Kesalahan",
              confirmText: "Ok",
              type: "is-danger",
            });
          }
        },
      });
    },
    routeToPageRincianMortgageAccount(props) {
      this.$router.push(
        `/loan/mortgage-account/${this.encryptBASE64(
          props.row.mortgageAccountId
        )}`
      );
    },
    async authorizationMortgageAccount(props) {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      }
      this.$buefy.dialog.confirm({
        message: "Otorisasi Data Ini ?",
        type: "is-success",
        onConfirm: async () => {
          try {
            const resp = await this.$store.dispatch({
              type: "PUT_DATA_AUTHORIZED",
              endPoint: "loan",
              reqUrl:
                "mortgage-account/authorized/" + props.row.mortgageAccountId,
            });
            if (resp.data.code === "SUCCESS") {
              this.$buefy.toast.open(this.$NotificationUtils.success);
              setTimeout(() => {
                this.property.listElement.mortgageAccount.currentPage = 1;
                this.property.filterDto.mortgageNumber = "";
                this.property.filterDto.cifIdName = "";
                this.property.filterDto.dateFrom = "";
                this.property.filterDto.dateTo = "";
                this.property.filterDto.statusId = "";
                this.property.listElement.mortgageAccount.rows = 0;
                this.getMortgageAccount();
              }, 1000);
            } else if (resp.data.code === "PLEASE_WAIT_IN_THE_PROCESS") {
              this.$buefy.dialog.alert({
                title: "Harap Tunggu !",
                message: resp.data.errorFields
                  ? this.globalNotificationErrorFieldToText(
                      resp.data.errorFields
                    )
                  : resp.data.message,
                confirmText: "Ok",
                type: "is-danger",
              });
            } else {
              this.$buefy.dialog.alert({
                title: "Gagal !",
                message: resp.data.errorFields
                  ? this.globalNotificationErrorFieldToText(
                      resp.data.errorFields
                    )
                  : resp.data.message,
                confirmText: "Ok",
                type: "is-danger",
              });
              // this.globalErrorFields(resp);
            }
          } catch (error) {
            this.$buefy.dialog.alert({
              title: "Gagal !",
              message: error.response
                ? error.response.data.errorFields
                  ? this.globalNotificationErrorFieldToText(
                      error.response.data.errorFields
                    )
                  : error.response.statusText
                : "Terjadi Kesalahan",
              confirmText: "Ok",
              type: "is-danger",
            });
          }
        },
      });
    },
    handleErrorCif(error) {
      this.table.data.cif = [];
      this.property.listElement.cif.rows = 0;
      this.property.listElement.cif.currentPage = 1;
      this.property.listElement.cif.message = error.response
        ? `Gagal Mengunduh Data : ${error.response.statusText}`
        : "Terjadi Kesalahan";
    },
    async changePaginationCif(event) {
      this.table.data.cif = [];
      this.property.listElement.cif.downloading = true;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "transaction",
          reqUrl: "cif/simple-list",
          payload: {
            cifNumber: this.session.cifChecking.cifNumber,
            officeCode: this.session.cifChecking.officeCode,
            cifIdName: this.session.cifChecking.cifIdName,
            categoryId: "",
            cifMotherName: "",
            page: event - 1,
            size: this.property.listElement.cif.perPage,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.cif = resp.data.data.content;
            this.property.listElement.cif.rows = resp.data.data.totalElements;
          } else {
            this.failedGetCif(resp);
          }
        }, 500);
      } catch (error) {
        setTimeout(() => {
          this.handleErrorCif(error);
        }, 500);
      } finally {
        setTimeout(() => {
          this.property.listElement.cif.downloading = false;
        }, 500);
      }
    },
    failedGetCif(resp) {
      this.table.data.cif = [];
      this.property.listElement.cif.rows = 0;
      this.property.listElement.cif.message = resp.data.message;
    },
    handleErrorGetMortgageAccount(error) {
      console.log(error.response);
      this.table.data.mortgageAccount = [];
      this.property.listElement.mortgageAccount.rows = 0;
      this.property.listElement.mortgageAccount.currentPage = 1;
      this.property.listElement.mortgageAccount.message = error.response
        ? `Gagal Mengunduh Data : ${error.response.statusText}`
        : "Terjadi Kesalahan";
    },
    async changePaginationMortgageAccount(event) {
      this.table.data.mortgageAccount = [];
      this.property.listElement.mortgageAccount.downloading = true;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "loan",
          reqUrl: "mortgage-account/simple-list",
          payload: {
            mortgageNumber: this.property.filterDto.mortgageNumber,
            cifIdName: this.property.filterDto.cifIdName,
            dateFrom: this.property.filterDto.dateFrom,
            dateTo: this.property.filterDto.dateTo,
            statusId: this.property.filterDto.statusId,
            page: event - 1,
            size: this.property.listElement.mortgageAccount.perPage,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.mortgageAccount = resp.data.data.page.content;
            this.property.listElement.mortgageAccount.rows =
              resp.data.data.page.totalElements;
            this.table.totalRekening = resp.data.data.totalMortgageAccount;
            this.table.totalNominalMortgage =
              resp.data.data.totalNominalMortgage;
          } else {
            this.failedGetMortgageAccount(resp);
            this.table.totalRekening = 0;
            this.table.totalNominalMortgage = 0;
          }
        }, 500);
      } catch (error) {
        setTimeout(() => {
          this.handleErrorGetMortgageAccount(error);
        }, 500);
      } finally {
        setTimeout(() => {
          this.property.listElement.mortgageAccount.downloading = false;
        }, 500);
      }
    },
    async getMortgageAccount() {
      this.table.data.mortgageAccount = [];
      this.property.listElement.mortgageAccount.downloading = true;
      const timeout = 500;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "loan",
          reqUrl: "mortgage-account/simple-list",
          payload: {
            mortgageNumber: "",
            cifIdName: "",
            dateFrom: "",
            dateTo: "",
            statusId: "",
            page: 0,
            size: 10,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.mortgageAccount = resp.data.data.page.content;
            this.property.listElement.mortgageAccount.rows =
              resp.data.data.page.totalElements;
            this.table.totalRekening = resp.data.data.totalMortgageAccount;
            this.table.totalNominalMortgage =
              resp.data.data.totalNominalMortgage;
          } else {
            this.failedGetMortgageAccount(resp);
            this.table.totalRekening = 0;
            this.table.totalNominalMortgage = 0;
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleErrorGetMortgageAccount(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.mortgageAccount.downloading = false;
        }, timeout);
      }
    },
    failedGetMortgageAccount(resp) {
      this.property.listElement.mortgageAccount.rows = 0;
      this.table.data.mortgageAccount = [];
      this.property.listElement.mortgageAccount.message = resp.data.message;
    },
    async getReferenceOffice() {
      try {
        const resp = await this.$store.dispatch(
          "GET_REFERENCE_FROM_MASTER_PARAMETER",
          {
            url: "office",
            params: {
              officeName: "",
              page: 0,
            },
          }
        );
        if (resp.data.code === "SUCCESS") {
          this.options.office = [{ value: "", text: "-- Cabang Saat Ini --" }];
          resp.data.data.content.map((data) => {
            var value = data.officeCode;
            var text = data.officeName;
            this.options.office.push({ text, value });
          });
        }
      } catch (error) {}
    },
    routeToPageMortgagePaidOff(props) {
      this.$router.push(
        `/loan/mortgage-paid-off/new?refId=${this.encryptBASE64(
          JSON.stringify({
            mortgageAccountId: props.row.mortgageAccountId,
          })
        )}`
      );
    },
    async getReferenceStatusAccount() {
      try {
        const resp = await this.$store.dispatch("GET_REFERENCE_PARAMETER", {
          url: "status",
        });
        if (resp.data.code === "SUCCESS") {
          this.options.statusAccount = [{ value: "", text: "-- Semua --" }];
          resp.data.data.map((index) => {
            const text = index.statusName;
            const value = index.statusId;
            this.options.statusAccount.push({ text, value });
          });
        }
      } catch (error) {}
    },
  },
  mounted() {
    this.getMortgageAccount();
    this.getReferenceOffice();
    this.getReferenceStatusAccount();
  },
};
